import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'CERTS_VIEW', {
  TITLE: 'Certificados de la empresa',
  DATE_CREATED: 'Fecha de emisión',
  CAPSULE: 'Cápsula',
  DOWNLOAD: 'Descargar',
  FILE: 'Archivo',
  SHARE: 'Compartir en Linkedin',
  SHARE_BUTTON: 'Compartir',
});
