import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import Carousel from 'components/commons/Carousel';
import Loading from 'components/commons/Loading';
import { ITraining } from 'types/training.types';
import history from 'helpers/history';
import { SIZES } from 'helpers/enums/sizes';
import { isInTheFuture } from 'helpers/dateCalculations';
import FeaturedTraining from 'components/commons/FeaturedTraining';
import { MenuRoutes } from 'helpers/enums/links';

import TrainingCard from './components/TrainingCard';
import { ICapsuleViewProps } from './types';
import {
  Body,
  CarouselContainer,
  ContentContainer,
  ProgressContainer,
  AvailableTrainingsContainer,
  ProgressTitle,
  ProgressDescription,
  AvailableTitle,
  ButtonContainer,
  CapsuleTitleText,
  GrayLine,
  BackButton,
  DefaultText,
  LeftContainer,
  ViewContainer,
  ProgressThumbnail,
} from './styles';
import './i18n';

const CapsuleView: FunctionComponent<ICapsuleViewProps> = (props: ICapsuleViewProps) => {
  const {
    trainings,
    currentCapsule,
    onSubmit,
    user,
    forms,
    completeForm,
    showCertModal,
    closeCertModal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getVersionTrainings,
    capsuleLoading,
    trainingsLoading,
    currentVersion,
    showAllTrainings,
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isMobile = windowWidth <= SIZES.desktop;

  const versionsArray: number[] = [];

  for (let version = currentVersion - 1; version < currentVersion; version++) {
    versionsArray.unshift(version);
  }
  // TURN OFF OLD TRAININGS
  // const [selectedPastVersion, setSelectedPastVersion] = useState<number>(currentVersion - 1);
  // const pastTrainings = trainings[selectedPastVersion];
  // const onClickSelectVersion = (version: number) => {
  //   if (!trainings[version].length) getVersionTrainings(version.toString());
  //   setSelectedPastVersion(version);
  // };
  const handleAvailableTrainings = () => {
    if (showAllTrainings) {
      const renderAllTrainings = [];
      for (let version = currentVersion; version > 2019; version--) {
        if (trainings[version].length > 0) {
          renderAllTrainings.push(trainings[version]);
        }
      }
      return renderAllTrainings.flat();
    }
    const availableTrainings = trainings[currentVersion];
    return availableTrainings.concat(trainings[currentVersion - 1]);
  };

  const availableTrainings = handleAvailableTrainings();
  const featuredTraining = availableTrainings
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .filter((training) => !isInTheFuture(new Date(), new Date(training.date)))[0];

  const CardModel = (item: ITraining): JSX.Element => {
    return (
      <TrainingCard
        key={item?.id}
        onSubmit={onSubmit}
        training={item}
        user={user}
        forms={forms}
        completeForm={completeForm}
        showCertModal={showCertModal}
        closeCertModal={closeCertModal}
        currentVersion={currentVersion}
      />
    );
  };

  const renderFeaturedTraining = () =>
    featuredTraining && (
      <FeaturedTraining featuredTraining={featuredTraining} capsuleTitle={currentCapsule.title} />
    );

  const renderTrainings = (trainingsToRender: ITraining[]) => (
    <CarouselContainer>
      {trainingsToRender?.length ? (
        <Carousel
          autoPlay={false}
          items={trainingsToRender}
          renderItem={CardModel}
          infinite={false}
        />
      ) : (
        <Body>{i18next.t('TRAININGS:EMPTY_ARRAY')}</Body>
      )}
    </CarouselContainer>
  );

  return (
    <ViewContainer>
      {!currentCapsule.id.length && <Redirect to="/error" />}
      <ButtonContainer>
        <BackButton onClick={() => history.push(MenuRoutes.DASHBOARD)} />
        <GrayLine />
        <DefaultText onClick={() => history.push(MenuRoutes.DASHBOARD)}>
          {i18next.t('TRAININGS:HOME')}
        </DefaultText>
        <GrayLine isLast />
        <CapsuleTitleText onClick={() => isMobile && history.push(MenuRoutes.DASHBOARD)}>
          {isMobile ? i18next.t('TRAININGS:HOME') : currentCapsule.title}
        </CapsuleTitleText>
      </ButtonContainer>
      <ContentContainer>
        <LeftContainer>
          {renderFeaturedTraining()}
          <AvailableTrainingsContainer>
            <AvailableTitle>{i18next.t('TRAININGS:AVAILABLE_TRAININGS')}</AvailableTitle>
            {capsuleLoading || trainingsLoading ? <Loading /> : renderTrainings(availableTrainings)}
          </AvailableTrainingsContainer>
          {/* TURN OFF OLD TRAININGS */}
          {/* <PastTrainingsContainer>
            <PastTitle>{i18next.t('TRAININGS:PAST_VERSIONS')}</PastTitle>
            <SelectVersionContainer>
              {pastTrainings.length > 0 &&
                versionsArray.map((version) => (
                  <SelectVersionButton
                    key={version}
                    isSelectedVersion={selectedPastVersion === version}
                    onClick={() => onClickSelectVersion(version)}
                  >
                    {version}
                  </SelectVersionButton>
                ))}
            </SelectVersionContainer>
            {capsuleLoading || trainingsLoading ? <Loading /> : renderTrainings(pastTrainings)}
          </PastTrainingsContainer> */}
        </LeftContainer>
        <ProgressContainer>
          <ProgressTitle>{currentCapsule.title}</ProgressTitle>
          {currentCapsule.descriptionThumbnail && (
            <ProgressThumbnail thumbnailUrl={currentCapsule.descriptionThumbnail} />
          )}
          <ProgressDescription>{currentCapsule.description}</ProgressDescription>
        </ProgressContainer>
      </ContentContainer>
    </ViewContainer>
  );
};

export default CapsuleView;
