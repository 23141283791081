import { ITraining } from 'types/training.types';
import history from 'helpers/history';
import {
  isInTheFuture,
  isLessThanOneHourAndHalfAfter,
  isLessThanTenMinBefore,
} from './dateCalculations';

export const isFutureLiveTraining = (now: Date, trainingDate: Date) =>
  isInTheFuture(now, trainingDate) ||
  isLessThanTenMinBefore(now, trainingDate) ||
  isLessThanOneHourAndHalfAfter(now, trainingDate);

export const getFutureTrainings = (trainings: ITraining[]): ITraining[] => {
  const now = new Date();
  return trainings
    .filter((training) => isInTheFuture(now, new Date(training.date)))
    .sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
};

export const getPastTrainings = (trainings: ITraining[]): ITraining[] => {
  const now = new Date();
  return trainings
    .filter((training) => !isInTheFuture(now, new Date(training.date)))
    .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
};

export const redirectToTraining = (trainingId: string, open?: boolean) => {
  history.push(`/capacitaciones/${trainingId}${open ? '/open' : ''}`);
};

export const getDashboardFeaturedTraining = (trainings: ITraining[]): ITraining | undefined => {
  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  let featuredTraining: ITraining | undefined;

  const recentTrainings = trainings.filter((training) => {
    return new Date(training.date) > lastMonth && new Date(training.date) <= today;
  });
  if (recentTrainings.length) {
    featuredTraining = recentTrainings.reduce((prev, current) =>
      new Date(current.date) > new Date(prev.date) ? current : prev,
    );
  }

  return featuredTraining;
};
