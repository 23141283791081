import React, { FunctionComponent } from 'react';
import moment from 'moment';
import i18next from 'i18next';

import Loading from 'components/commons/Loading';

import { ICertificate } from 'types/certs.types';
import { ICertificatesViewProps } from './types';
import './i18n';
import {
  CertsInfoContainer,
  CertsTableRow,
  Title,
  CertsTable,
  ViewContainer,
  DownloadButton,
  CertsTitleContainer,
} from './styles';

const CertificatesView: FunctionComponent<ICertificatesViewProps> = (
  props: ICertificatesViewProps,
) => {
  const { certificates } = props;

  const shareOnLinkedin = (certificate: ICertificate) => {
    const certificateName = encodeURIComponent(certificate.capsule.title);
    const year = encodeURIComponent(new Date(certificate.dateCompleted).getFullYear());
    const month = encodeURIComponent(new Date(certificate.dateCompleted).getMonth() + 1);
    const pdfLink = encodeURIComponent(certificate.url);

    const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${certificateName}&issueYear=${year}&issueMonth=${month}&certUrl=${pdfLink}&credentialDoesNotExpire=1&organizationId=1248`;
    window.open(url, '_blank');
  };

  return (
    <ViewContainer>
      <Title>{i18next.t('CERTS_VIEW:TITLE')}</Title>
      <CertsTable>
        <CertsTableRow>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:DATE_CREATED')}</CertsTitleContainer>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:CAPSULE')}</CertsTitleContainer>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:FILE')}</CertsTitleContainer>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:SHARE')}</CertsTitleContainer>
        </CertsTableRow>
        {certificates ? (
          certificates.map((certificate) => (
            <CertsTableRow>
              <CertsInfoContainer>
                {moment(certificate.dateCompleted).format('ll')}
              </CertsInfoContainer>
              <CertsInfoContainer>{certificate.capsule.title}</CertsInfoContainer>
              <CertsInfoContainer>
                <DownloadButton href={certificate.url} target="_blank">
                  {i18next.t('CERTS_VIEW:DOWNLOAD')}
                </DownloadButton>
              </CertsInfoContainer>
              <CertsInfoContainer>
                <DownloadButton onClick={() => shareOnLinkedin(certificate)}>
                  {i18next.t('CERTS_VIEW:SHARE_BUTTON')}
                </DownloadButton>
              </CertsInfoContainer>
            </CertsTableRow>
          ))
        ) : (
          <Loading />
        )}
      </CertsTable>
    </ViewContainer>
  );
};

export default CertificatesView;
