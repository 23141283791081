import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const Title = styled.h2`
  font: 20px/1 'OpenSansBold';
  color: ${COLORS.blueResolution};
`;

export const CertsTable = styled.div`
  width: 100%;
  border-radius: 8px;
  color: ${COLORS.riverBed};
  background: ${COLORS.grayCatskill};
  flex-flow: row wrap;
`;

export const CertsTableRow = styled.div`
  padding: 20px 30px;
  display: flex;
`;

export const CertsInfoContainer = styled.div`
  flex: 1;
  font: 14px/1.2 'OpenSans';
  margin: 5px 0;
`;

export const CertsTitleContainer = styled(CertsInfoContainer)`
  font-weight: bold;
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1296px;
  padding: 0 50px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 15px;
  }
`;

export const DownloadButton = styled.a`
  padding: 5px;
  border-radius: 10px;
  background-color: ${COLORS.blueSanMarino};
  color: white;
  font-weight: bold;
  cursor: pointer;
`;
