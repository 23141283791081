import styled, { css } from 'styled-components';

import { ReactComponent as icCalendar } from 'assets/imgs/icon-calendar.svg';
import { ReactComponent as icClock } from 'assets/imgs/icon-clock.svg';
import { COLORS } from 'helpers/enums/colors';
import Button from 'components/commons/Button';
import { SIZES } from 'helpers/enums/sizes';

export const TrainingThumbnail = styled.div<ITrainingCardStyles>`
  width: 213px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s;
  }
`;

export const TrainingContainer = styled.div<ITrainingCardStyles>`
  width: 213px;
  height: 280px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${(props: ITrainingCardStyles) =>
    props.isHome &&
    css`
      width: 100%;
      min-width: 184px;
      height: 286px;

      @media (max-width: ${SIZES.desktop}px) {
        min-width: 100%;
        height: 210px;
        align-items: flex-start;
      }
    `}
`;

const ScrollBar = css`
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TrainingTitle = styled.div<ITrainingCardStyles>`
  width: 100%;
  align-self: flex-start;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  font-family: OpenSansBold;
  color: ${COLORS.riverBed};
  margin-bottom: 5px;
  word-wrap: break-word;
  max-height: ${(props: ITrainingCardStyles) => (props.showVideoLength ? '61px' : '86px')};
  ${ScrollBar}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TrainingContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  box-sizing: border-box;
`;

export const CustomButton = styled(Button)`
  width: 90px;
  height: 35px;
  border-radius: 4px;
  font-size: 9px;
  line-height: 15px;
  font-family: OpenSansBold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const WatchTrainingButton = styled(CustomButton)`
  &:not([disabled]) {
    background-color: ${COLORS.blueResolution};
    color: ${COLORS.white};
    :hover {
      background: ${COLORS.denim};
    }
  }

  &[disabled] {
    background-color: ${COLORS.riverBed};
    color: ${COLORS.white};
  }
`;

export const SubmitCodeButton = styled(CustomButton)`
  background-color: ${COLORS.grayCatskill};
  color: ${COLORS.fuchsiaBlue};

  :hover:not([disabled]) {
    background: ${COLORS.rainbowSkyPurple};
    color: ${COLORS.white};
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ModalButton = styled(Button)`
  margin-top: 15px;
`;

export const FutureTrainingTitle = styled.div<ITrainingCardStyles>`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  font-family: OpenSansBold;
  color: ${COLORS.riverBed};
  max-height: 70px;
  ${ScrollBar}

  ${(props: ITrainingCardStyles) =>
    props.isHome &&
    css`
      text-align: center;
      @media (max-width: ${SIZES.desktop}px) {
        text-align: left;
      }
    `}
`;

export const TrainingDateInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-family: 'OpenSans';
  margin: 0 15px 0 0;
  padding: 0;
  gap: 5px;
  ${(props: ITrainingCardStyles) =>
    props.isHome &&
    css`
      @media (max-width: ${SIZES.desktop}px) {
        flex-direction: row;
      }
    `}
`;

export const TrainingDateInfoText = styled.div`
  display: flex;
`;

export const IconCalendar = styled(icCalendar)`
  width: 15px;
  margin-right: 5px;
  g {
    .st0 {
      fill: ${COLORS.riverBed};
    }
  }
`;
export const LengthContainer = styled.div`
  width: 100%;
  font: 'OpenSans';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${COLORS.riverBed};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const IconClock = styled(icClock)`
  width: 14px;
  margin-right: 5px;

  g {
    .st0 {
      fill: ${COLORS.riverBed};
    }
  }
`;

export const TrainingDateText = styled.div`
  font-weight: bold;
  margin-left: 4px;
`;

export const FutureTrainingTopContainer = styled.div<ITrainingCardStyles>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: ${(props: ITrainingCardStyles) =>
    props.isHome ? 'space-around' : 'space-between'};
  align-items: center;
  box-sizing: border-box;
  padding: ${(props: ITrainingCardStyles) => (props.isHome ? '0' : '36px')} 25px;
  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const FutureTrainingBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background: ${COLORS.blueResolution};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  font-family: OpenSansBold;
  color: ${COLORS.pearlEssenceGray};

  :hover {
    background: ${COLORS.denim};
  }
`;

export const SoonText = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  font-family: OpenSansBold;
  color: ${COLORS.slateMistGray};
  opacity: 0.5;
  @media (max-width: ${SIZES.desktop}px) {
    align-self: center;
  }
`;

export const Separator = styled.div`
  display: none;

  ${(props: ITrainingCardStyles) =>
    props.isHome &&
    css`
      @media (max-width: ${SIZES.desktop}px) {
        display: block;
        width: 0;
        height: 15px;
        margin: 0 8.5px;
        border: 1px solid ${COLORS.riverBed};
      }
    `}
`;

interface ITrainingCardStyles {
  isHome?: boolean;
  showVideoLength?: string;
}
