import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, SwitchProps } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import FacebookPixel from 'react-facebook-pixel';

import { onInitialize } from 'redux/actions/app.actions';

import { FB_PIXEL } from 'configs/configs';
// import HomeContainer from 'containers/HomeContainer';
import SurveyCallbackView from 'views/SurveyCallbackView';
import LoginContainer from 'containers/LoginContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ForgotPasswordContainer from 'containers/ForgotPasswordContainer';
import ConfirmAccountContainer from 'containers/ConfirmAccountContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import UserLoggedContainer from 'containers/UserLoggedContainer';
import TermsAndConditionsContainer from 'containers/TermsAndConditionsContainer';
import PrivateRoute from 'containers/PrivateRoute';
import ScrollToTopContainer from 'containers/ScrollToTopContainer';
import ProfileContainer from 'containers/ProfileContainer';
import ProgramsContainer from 'containers/ProgramsContainer';
import CapsuleContainer from 'containers/CapsuleContainer';
import TrainingDetailContainer from 'containers/TrainingDetailContainer';
import NewsAndTestimonialsContainer from 'containers/NewsAndTestimonialsContainer';
import NotificationsContainer from 'containers/NotificationsContainer';
import ConfirmSignupContainer from 'containers/ConfirmSignupContainer';
import ConfirmEmailAccountContainer from 'containers/ConfirmEmailAccountContainer';
import AcceptAccountContainer from 'containers/AcceptAccountContainer';
import ErrorView from 'views/ErrorView';
import EmployeesContainer from 'containers/EmployeesContainer';
import ConfirmAssistanceContainer from 'containers/ConfirmAssistanceContainer';
import CertificatesContainer from 'containers/CertificatesContainer';

import history from 'helpers/history';
import * as appActions from 'redux/actions/app.actions';

import { GlobalStyle } from './styles';
import 'react-notifications/lib/notifications.css';

const App: FunctionComponent<SwitchProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onInitialize());
  }, [dispatch]);

  FacebookPixel.init(FB_PIXEL, undefined, { debug: false, autoConfig: true });

  const handleRouteChange = useCallback(() => {
    dispatch(appActions.changeRoute(history.location.pathname));
  }, [dispatch]);

  useEffect(() => {
    handleRouteChange();
    const unListen = history.listen(handleRouteChange);
    return unListen;
  }, [handleRouteChange]);

  return (
    <>
      <GlobalStyle />
      <NotificationContainer />
      <ScrollToTopContainer />
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        {/* <Route path="/home" component={HomeContainer} /> */}
        <Route path="/survey-callback" component={SurveyCallbackView} />
        <Route path="/login" component={LoginContainer} />
        <PrivateRoute path="/dashboard" component={UserLoggedContainer} />
        <PrivateRoute path="/perfil" component={ProfileContainer} />
        <PrivateRoute path="/noticias" component={NewsAndTestimonialsContainer} />
        {/* Turn off benefits
        <PrivateRoute path="/beneficios" component={BenefitsContainer} /> */}
        <PrivateRoute path="/programas" component={ProgramsContainer} />
        <PrivateRoute path="/colaboradores" component={EmployeesContainer} />
        <PrivateRoute path="/notificaciones" component={NotificationsContainer} />
        <PrivateRoute path="/capsulas/:capsuleId" component={CapsuleContainer} />
        <PrivateRoute
          path="/capacitaciones/:trainingId/:behaviour?"
          component={TrainingDetailContainer}
        />
        <PrivateRoute path="/certificados" component={CertificatesContainer} />
        <Route path="/signup" component={SignUpContainer} />
        <Route path="/confirm-signup" component={ConfirmSignupContainer} />
        <Route path="/confirm-email-account" component={ConfirmEmailAccountContainer} />
        <Route path="/forgot-password" component={ForgotPasswordContainer} />
        <Route path="/confirmar-cuenta/:token" component={ConfirmAccountContainer} />
        <Route path="/aceptar-cuenta/:token" component={AcceptAccountContainer} />
        <Route
          path="/confirmar-asistencia/:userId/:trainingId"
          component={ConfirmAssistanceContainer}
        />
        <Route path="/reset-password/:token" component={ResetPasswordContainer} />
        <Route path="/terminos-y-condiciones" component={TermsAndConditionsContainer} />
        <Route component={ErrorView} />
      </Switch>
    </>
  );
};

export default App;
