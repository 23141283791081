export const SIZES = {
  smallPhone: 320,
  mediumPhone: 480,
  bigPhone: 575,
  smallTablet: 600,
  mediumTablet: 767,
  bigTablet: 991,
  smallDesktop: 1024,
  desktop: 1199,
  bigDesktop: 1366,
  // Turn off benefits
  // benefitsBanner: 36,
  benefitsBanner: 0,
};
